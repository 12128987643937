html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: monospace;
  font-size: 1rem;
  background: #007bff;
  border-radius: 5px;
  padding: 5px 6px;
  color: white;
}

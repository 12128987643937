// Common Variables
$primary-color: #007bff;
$bright-yellow: #fae13d;
$black-opacity: rgba(255, 255, 255, 0.2);

// Font Variables
$heading-font-family: Montserrat, "Helvetica Neue", Helvetica,Arial, sans-serif;
$heading-font-weight: 700;

// Font Attributes
$text-shadow: 0 0 9px black;

// Common Mixins
@mixin common-heading-styles {
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
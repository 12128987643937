@import '../../assets/styles/shared';

//Navbar common properties
$transparent-bg: transparent !important;
$transition-bg: background-color 0.3s ease;
$bg-opacity: rgba(0, 0, 0, 0.8) !important;

.navbar {
  position: fixed;
  width: 100%;
  transition: $transition-bg;

  &.transparent-navbar.navbar-light {
    background-color: $transparent-bg;
    box-shadow: none;

    > .container-fluid:has(.navbar-collapse.collapse.show) {
      background-color: $bg-opacity;
      transition: $transition-bg;
    }
  }
  
  &.scrolled.navbar-light {
    background-color: $bg-opacity;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    .container-fluid:has(.navbar-collapse.collapse.show) {
      background-color: $transparent-bg;
    }
  }
  
  .navbar-brand {
    color: white;
    font-weight: 200;

    span {
      font-weight: 900;
    }

    .highlighted-text {
      color: white;
      transition: color 0.3s ease;
    }
  
    &:hover .highlighted-text {
      color: $primary-color;
    }
  }

  .nav-link {
    color: #eee;
    font-size: 1rem;
    margin: 0 0.5rem;

    &.active {
      color: $primary-color;
    }

    &:hover {
      color: $bright-yellow;
    }
  }

  .navbar-toggler {
    border: none;

    :focus {
      box-shadow: none;
      outline: none;
    }
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}
// Override Bootstrap variables
$primary: #007bff;
$font-family-sans-serif: 'Roboto', sans-serif; // Change the default font

// Import Bootstrap SCSS for recompilation
@import 'bootstrap/scss/bootstrap';

body {
  background-color: transparent !important;
}

.custom-button {
  background-color: $primary;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;

  &:hover {
    background-color: darken($primary, 10%);
  }
}


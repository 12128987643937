#css-development {

  .dev-section {
    background-color: #1E104F;
    clip-path: polygon(0 70px,100% 0,100% 100%,0 100%);
    padding: 9rem 0 2rem;
    position: relative;
    margin-top: -72px;

    .call-to-action {
      min-height: 250px;
    }
  }

  a.btn {
    color: white;
  }
}
@import '../../assets/styles/shared';

.App {
  text-align: center;
  
  h1 {
    color: $bright-yellow;
    text-shadow: $text-shadow;
    margin-bottom: 2rem;
    @include common-heading-styles;

    span {
      font-weight: 300;
    }
  }

  h2.home-description {
    color: white;
    font-family: $heading-font-family;
    font-size: 1.25rem;
    font-weight: 400;
    text-shadow: $text-shadow;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $primary-color;
    @include common-heading-styles;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
}

@import '../../assets/styles/shared';

#about {
  text-align: left;

  .section-1, .section-2 {
    background-color: #e0d9fa;
  }

  .section-1 {
    height: 110vh;
    padding: 5rem 0;
    clip-path: polygon(0 0,100% 0,100% calc(100% - 70px),0 100%);
    position: relative;
    margin-bottom: -66px;
  }

  .section-2 {
    padding: 5.5rem 0 4rem;
    clip-path: polygon(0 70px,100% 0,100% calc(100% - 70px),0 100%);
    position: relative;
    margin-top: -66px;
  }

  .about-hr {
    margin: 2rem;
    border: 2px solid white;
    opacity: 1;
  }

  .example-section h4 {
    margin-bottom: 2rem !important;
  }
}


@media (max-width: 576px) {
  #about {

    p, h4 {
      text-wrap: balance;
    }

    .section-1, .section-2 {
      img {
        scale: 90%;
      }
    }

    .section-1 {
      height: 175vh;
      padding: 5rem 0 5px;
    }

    .section-2 {
      padding: 8rem 0 5rem;
    }
  }
}